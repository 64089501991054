import React, { useContext } from "react"
import useLocationBrowser from "react-use/lib/useLocation"

const LocationContext = React.createContext(null)

export default LocationContext

export const useLocation = () => {
  const gatsbyLocation = useContext(LocationContext)
  const browserLocation = useLocationBrowser()

  return browserLocation.pathname ? browserLocation : gatsbyLocation
}
