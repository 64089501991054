import React from "react"

import { useI18n } from "../contexts/i18n"
import Layout from "../layout"
import SEO from "../components/SEO"

const NotFoundPage = () => {
  const { translate: t } = useI18n(`404`)

  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>{t`NOT FOUND`}</h1>
      <p>{t`Page not found - we suggest watching a movie?`}</p>
    </Layout>
  )
}

export default NotFoundPage
