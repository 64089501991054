import React from "react"
import { Link as NoI18nLink } from "gatsby"
import cx from "classnames"

import { useI18n, LocaleLink as Link } from "../../contexts/i18n"
import { useLocation } from "../../contexts/location"
import { locales, localePath, basePath } from "../../../locales"

// @ts-ignore
import logo from "../../_img/logo.png"

function Header() {
  const { locale, translate: t } = useI18n(`layout`)
  const location = useLocation()

  const path = basePath(location?.pathname ?? "", locale?.name)

  return (
    <header className="header scrolling">
      <Link to="/">
        <div className="logo-container">
          <img src={logo} alt="GCFlix logo" />
        </div>
      </Link>
      <nav className="navigation">
        <ul className="navigation-items">
          <li>
            {Object.keys(locales).map((name) => (
              // @ts-ignore
              <NoI18nLink
                key={name}
                to={localePath(path, name)}
                getProps={() => ({
                  className: cx(`nav-link ${name}`, {
                    active: locale?.name === name,
                  }),
                })}
              >
                {name}
              </NoI18nLink>
            ))}
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
