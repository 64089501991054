import React from "react"
import cx from "classnames"

import Header from "./Header"
import Footer from "./Footer"
import "../../_styles/styles.scss"

interface LayoutProps {
  narrow?: boolean
  children: React.ReactNode
}

function Layout({ narrow, children }: LayoutProps) {
  return (
    <>
      <Header />
      <main className={cx(`container`, { narrow })}>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
