import React from "react"

import { useI18n, LocaleLink as Link } from "../../contexts/i18n"

function Footer() {
  const { translate: t } = useI18n(`layout`)

  return (
    <footer className="footer container">
      <ul className="footer-menu">
        <li>
          <Link to="/media">{t`Media`}</Link>
        </li>
        <li>
          <Link to="/terms-of-use">{t`Terms of use`}</Link>
        </li>
        <li>
          <Link to="/privacy-policy">{t`Privacy Policy`}</Link>
        </li>
        <li>
          <Link to="/license-agreement">{t`License Agreement`}</Link>
        </li>
        <li>
          <Link to="/contact">{t`Contact`}</Link>
        </li>
        <li>
          <Link to="/about-us">{t`About Us`}</Link>
        </li>
        <li>
          <Link to="/faq">{t`FAQ`}</Link>
        </li>
      </ul>
      <p className="footer-legal">© {new Date().getFullYear()} GCFlix.</p>
    </footer>
  )
}

export default Footer
